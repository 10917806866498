@import "src/styles/App.scss";
@import "src/styles/variables.scss";

.dashboard {
  // @extend .container-wide;

  // margin: 2.5rem auto;

  // TODO: deduplicate with Brands scss
  &__content {

    // &:not(:first-child) {
    //   padding: 2rem 0;
    // }

    &__labelled-data {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      > span {
        @extend .fs-5;
        display:inline-flex;
        flex-direction: column;
      }
    }
  }

  .card {
    background-color: $light;
    height: 100%;
    // TODO: temp styling to reduce overemphasized borders
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;

    .card-title > * {
      margin: 0;
    }

    .card-body:not(:first-child) {
      padding-top: 0;
    }
    .card-body:not(:last-child) {
      padding-bottom: 0;
    }
  }



}
