@import-sanitize; /* bring in normalize.css styles according to browserlist in package.json */

h1,
h1,
h3,
h4,
h5,
h6 {
  text-wrap: balance;
}

p {
  text-wrap: pretty;
  max-width: 75chl;
}

@media (prefers-reduced-motion: no-preference) {
  :has(:target) {
    scroll-behavior: smooth;
  }
}
