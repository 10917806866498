@import "./reset.scss";
@import "./customBootstrap.scss";
@import "./utilities";

.grecaptcha-badge {
  visibility: hidden;
}

html,
body,
#root {
  height: 100%;
}

.App {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  > main {
    flex-grow: 1;
    overflow-y: auto;
  }
}

.btn-link.active {
  text-decoration: none;
}

.badge {
  font-size: inherit;
  font-weight: inherit; // TODO: fix thick badge lettering
}

.nav-tabs {
  display: flex;
  align-items: center;
  border-bottom: none;
  > * {
    margin: 0;
  }

  > :first-child:not(.nav-item) {
    margin-right: 2.4rem;
  }
}

.error {
  color: $danger;
  // font-weight: bold;
}

.container-wide {
  @include make-container(100px);
}

.main-page {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: auto;

  &__content {
    > :first-child {
      padding-top: 1rem;
    }

    > :last-child {
      padding-bottom: 1rem;
    }
    @extend .container-wide;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0;
    margin-bottom: 0.8rem;
  }
  &__body {
    flex: 1;
    overflow: visible;
  }
}

// TODO: move
.milestone-modal,
.deliverable-modal {
  .modal-body > .data-label {
    display: none;
  }
  .modal-body > .data-value {
    margin-bottom: 1rem;
  }

  .data-value {
    .form-check-input {
      margin-left: 1rem;
    }
  }
}
.agreement-modal {
  .form-check-input {
    margin-left: 1rem;
  }
}

// TODO: move
.dashboard__content {
  overflow-y: auto;

  .main-page__body {
    overflow: visible;
  }
}

/**
 * Styles for card body element to expand and fill the height of the card.
 */
.card-body {
  display: flex;
  flex-direction: column;

  .tab-pane {
    flex: 1;
    overflow: auto;
  }
}

/**
 * Styles for creator and campaign details panel width
 */
.details-panel {
  min-width: fit-content;
  // max-width: 500px;

  > form {
    width: 100%;
  }
}

// TODO: move
$lighten-factor: 30%;
$darken-factor: 10%;

@each $color, $value in $theme-colors {
  .badge.bg-#{$color},
  .btn.btn-soft.btn-#{$color} {
    --bs-bg-opacity: 0.35;
    --bs-#{$color}-rgb: #{red(lighten($value, $lighten-factor))},
      #{green(lighten($value, $lighten-factor))},
      #{blue(lighten($value, $lighten-factor))};
    --soft-#{$color}-text-rgb: #{red(darken($value, $darken-factor))},
      #{green(darken($value, $darken-factor))},
      #{blue(darken($value, $darken-factor))};

    --bs-btn-color: rgb(var(--soft-#{$color}-text-rgb));
    --bs-btn-bg: rgba(var(--bs-#{$color}-rgb), var(--bs-bg-opacity));
    --bs-btn-border-color: rgba(
      var(--bs-#{$color}-rgb),
      var(--bs-border-opacity)
    );
  }

  .badge.bg-#{$color} {
    color: rgb(var(--soft-#{$color}-text-rgb));
  }
}
