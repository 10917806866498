section.milestones {
  height: 100%;
  width: 100%;
  position: relative;
  min-height: 400px;
}

.milestone-modal {
  .nav-tabs {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
