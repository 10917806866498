.expanded-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  height: 100%;
  width: 100%;
}

.fullscreen-overlay {
  @extend .expanded-overlay;

  position: fixed;

  // fallback units
  height: 100vh;
  width: 100vw;
  // modern units
  height: 100svh;
  width: 100svw;
}
