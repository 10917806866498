// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here

$primary: #060606;
$accent: #5925dc;
$enable-gradients: false;
$sidebar-width: 10rem;
$card-spacer-y: 2rem;
$card-spacer-x: 2rem;
$card-title-spacer-y: 1.2rem;
$font-size-base: 0.875rem; //14px
$border-color: #ced4da;

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/variables-dark";

// 2. Include any default map overrides here

$custom-colors: (
  "accent": $accent,
  "accent-dark": shade-color($accent, 20%),
  // Similar to Bootstrap's gray-400
  "secondary-soft": #a3a3a3,
  // Lighter shade of Bootstrap's blue
  // "primary-soft": #66a3ff,
  // "success-soft": #73c573,
  // "info-soft": #70a9cf,
  // "warning-soft": #ffb96a,
  // "danger-soft": #f76f6f,
);

$theme-colors: map-merge($theme-colors, $custom-colors);

$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.7;
$h3-font-size: $font-size-base * 1.4;
$h4-font-size: $font-size-base * 1.2;
$h5-font-size: $font-size-base * 1.1;
$h6-font-size: $font-size-base;

// Make SCSS variables available as CSS vars

:root {
  --bs-input-focus-border-color: #{$input-focus-border-color};
  --bs-input-transition: #{$input-transition};
  --bs-input-height: #{$input-height};
  --bs-input-height-sm: #{calc(
      1.5em + 0.5rem + calc(var(--bs-border-width) * 2)
    )};
  --bs-input-height-inner: #{$input-height-inner};
  --bs-input-height-inner-sm: #{add(
      $input-line-height * 1em,
      $input-padding-y-sm * 2
    )};
  --bs-input-border-radius: #{$input-border-radius};
  --bs-input-border-radius-sm: #{$input-border-radius-sm};
  --bs-modal-xl: 1500px;
}

// 3. Include remainder of required parts
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";
