@use "sass:color";
@import "src/styles/variables";
.quick-filter {
  position: relative;
  input {
    padding-right: 2rem;
  }
  &__clear {
    visibility: hidden;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 10;
    pointer-events: auto;
    cursor: pointer;
  }
  &--applied {
    > :first-child {
      color: #077186;
      background-color: #d0ebf0;
    }
    > * {
      --bs-border-color: #9ddae6;
      border-color: #9ddae6;
    }
    .quick-filter__clear {
      visibility: visible;
    }
  }
}
