@import "src/styles/variables";

.milestones-upcoming,
.milestones-due,
.assignments-upcoming,
.assignments-due {
  height: 700px;
  max-height: 90svh
}

.payment-forecast {
  
  &__content {
    max-width: 700px;

    .week-row {
      background-color: $gray-200;
      font-weight: bold;
      border-top: 1px solid $gray-500;
      
      .ag-cell,
      .ag-cell>.ag-cell-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
    }
  }
}
