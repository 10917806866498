@import "../../styles/variables";

$panel-gutter-x: 3rem;
$panel-gutter-y: 1rem;

.panel {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  &__header {
    display: flex;
    align-items: stretch;
    flex-grow: 0;
    position: relative;
    // padding: .4rem 2rem;

    &__nav {
      min-height: 3rem;
      flex-grow: 0;
      width: $panel-gutter-x;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__content {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      &:last-child {
        margin-right: $panel-gutter-x;
      }
    }
  }
  &__header--unpadded {
    .panel__header__nav {
      width: 0;
    }

    .panel__header__content {
      margin-right: 0;
    }
  }

  &__title {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    width: 100%;

    > *,
    label {
      margin: 0;

      &:not(:last-child) {
        margin-right: 1.6rem;
      }
    }

    label {
      // min-width: 150px; // TODO: make this a variable. is the same as label width of labeled data component
      margin-right: 1rem; // TODO: make this a variable. is the same as gap of labeled data component
    }
  }

  &__body {
    flex-grow: 1;
    display: block;
    overflow-y: hidden;
    border-top: $border-width solid $border-color;

    &--scroll {
      overflow-y: scroll;
    }

    &--borders {
      overflow-y: scroll;
      border: $border-width solid $border-color;
    }

    &--borderless {
      border-color: transparent;
    }

    &--split-vertical,
    &--split-horizontal {
      // > * {
      //   flex-shrink: 1;
      //   flex-grow: 1;
      // }
      // &:last-child {
      //   flex-shrink: 0;
      //   flex-grow: 1;
      //   // background-color: red;
      // }

      display: flex;
    }
    &--split-horizontal {
      flex-direction: column;
      > * {
        flex-grow: 1;
        &:not(:last-child) {
          border-bottom: $border-width solid $border-color;
          flex-shrink: 1;
        }
        &:first-child {
          flex-grow: 0;
        }
      }
    }
    &--split-vertical {
      > * {
        &:not(:last-child) {
          border-right: $border-width solid $border-color;
        }
      }
    }

    > .tab-pane {
      height: 100%;
    }
  }

  &__body--padded {
    padding: $panel-gutter-y $panel-gutter-x;
  }

  &__footer {
    flex-grow: 0;
    padding: $panel-gutter-y $panel-gutter-x;
    border-top: $border-width solid $border-color;
  }
}
