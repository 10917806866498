@import "../../styles/variables";

$panel-width: 700px;

.ag-theme-alpine {
  // TODO: debug why scss variables weren't working here
  --ag-borders: solid var(--bs-border-width);
  --ag-border-color: var(--bs-border-color);
  --ag-grid-size: 5px;
  --ag-list-item-height: 20px;
  .ag-root-wrapper {
    border: transparent;
  }
}

.data-grid-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .data-grid-header {
    display: flex;
    margin-bottom: 0.5rem;

    .data-grid-action {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .data-grid-filters {
    flex-grow: 1;
    flex: 1 1 auto;
    // padding-top: 5px; //* this fixed the cut off focus ring on quick filter, but added spacing outside of the component, hindering reusability

    > .row {
      gap: 1rem;
    }

    .applied-filter-chips {
      &__chip {
        display: flex;
        align-items: center;

        padding-left: 0.5rem;
        padding-right: 0.5rem;

        .remove-filter-x {
          margin: 0.1rem;
        }
      }
    }
  }

  .data-grid {
    flex: 1 1 auto;
  }
}

.data-grid {
  display: flex;
  flex-direction: column;

  &__body {
    height: 100%;
    flex: 1;

    .ag-header-cell-filtered {
      color: shade-color(#0aa2c0, 30%);
      background: initial;

      .ag-header-icon {
        color: shade-color(#0aa2c0, 30%);
      }

      &::before {
        content: "";
        pointer-events: none;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.2em;
        background-color: shade-color(#0aa2c0, 10%);
        opacity: 1;
      }
    }

    .cell-button {
      display: flex;
      align-items: center;

      // button {
      //   width: 3.5rem;
      // }
    }
  }

  &__footer {
    background-color: var(--ag-header-background-color);
    border-top: var(--ag-borders-critical) var(--ag-border-color);
    padding: 0.3rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > * {
      display: flex;
      align-items: center;
      gap: 2rem;
    }

    [class$="__actions"] {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  &__side-panel {
    width: 0;
    height: 100%;
    overflow-y: scroll;

    &.open {
      width: $panel-width;

      .item-details {
        right: 0;
        transition: right 0.5s cubic-bezier(0.4, 0, 0.2, 1);
      }
    }

    .section {
      padding: 1rem;

      &:not(:last-of-type) {
        border-top: 1px solid #ccc;
      }

      .content-panel-table {
        margin-bottom: 0;

        tr {
          label {
            margin-bottom: 0;
          }

          td:first-of-type {
            width: 130px;
            text-align: right;
          }
        }

        .form-label {
          margin-right: 0.5rem;
        }

        .clamp-text {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          -webkit-line-clamp: 4;
        }
      }
    }

    .item-details {
      position: fixed;
      top: 0;
      right: -100%;
      transition: right 0.5s cubic-bezier(0.4, 0, 0.2, 1);
      bottom: 0;
      width: $panel-width;
      height: 100%;
      z-index: 100;
      overflow-y: auto;
      overflow-x: hidden;
      background-color: skyblue;
    }

    .panel-footer {
      position: sticky;
      bottom: 0;
      background-color: $gray-200;
      min-width: $panel-width;
      margin-left: -1rem;
      margin-right: -1rem;
      visibility: hidden;
      transition: visibility 0.5s cubic-bezier(0.4, 0, 0.2, 1);

      &.dirty {
        visibility: visible;
        transition: visibility 0.5s cubic-bezier(0.4, 0, 0.2, 1);
      }

      .btn-group {
        width: 100%;
        height: 3.5rem;
      }
    }
  }
}
