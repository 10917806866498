@import "src/styles/utilities.scss";

.loader {
  @extend .expanded-overlay;
	// position: absolute;
	// top: 0;
	// bottom: 0;
	// left: 0;
	// right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;
  opacity: .9;
  &.solid {
    opacity: 1;
  }
}
