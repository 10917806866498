@import "src/styles/variables.scss";

.modal .expanded-image-modal.modal-dialog {
  width: unset;
  max-width: 90vw;
  height: unset;
  max-height: 90vh;
  margin-left: auto;
  margin-right: auto;

  padding-top: env(safe-area-inset-top);

  display: flex;

  align-items: center;
  justify-content: center;
  .modal-content {
    width: unset;
    max-width: 90vw;
    height: unset;
    max-height: 90vh;
  }
  .modal-header {
    padding: 5px 15px;
  }

  img {
    max-height: 90vh;
    max-width: 100%;
    object-fit: contain;
  }
}

.thumbnails {
  display: grid;
  grid-template-columns: repeat(auto-fit, 100px);
  grid-template-rows: repeat(auto-fit, minmax(0, 1fr));
  gap: 10px;

  & > div.thumbnail {
    .expand-image-btn {
      img {
        opacity: 1;
        transition: opacity 0.3s cubic-bezier(0.4, 0, 1, 1);
      }
    }
    &:hover,
    &:focus {
      .expand-image-btn {
        border-color: $accent;
        img {
          opacity: 0.6;
          transition: opacity 0.3s cubic-bezier(0.4, 0, 1, 1);
        }
      }
    }
  }

  button {
    border-color: transparent;
  }

  & > div,
  & > button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    aspect-ratio: 1/1;
    border-radius: 5px;
    border-color: transparent;
    overflow: hidden;
    box-shadow: none;

    &.upload-photo-btn {
      color: var(--bs-secondary-color);
      transition: all 0.1s cubic-bezier(0.4, 0, 1, 1);

      &:hover,
      &:focus {
        transition: all 0.1s cubic-bezier(0.4, 0, 1, 1);
        color: $accent;
        border: 1px solid;
        border-color: $accent;
        outline: transparent;
      }
    }

    .remove-image-btn {
      color: var(--bs-red);
    }

    .expand-image-btn {
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      border: 1px solid;
      border-color: var(--bs-border-color);
      overflow: hidden;
      border-radius: 5px;

      &:hover,
      &:focus {
        border-color: $accent;
      }

      &:hover {
        svg {
          transition: opacity 0.3s cubic-bezier(0.4, 0, 1, 1);
          opacity: 1;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        padding: 0;
        margin: 0;
      }

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: opacity 0.3s cubic-bezier(0.4, 0, 1, 1);
        color: $accent;
      }
    }

    .remove-image-btn {
      position: absolute;
      border: 1px solid;
      border-radius: 50%;
      background-color: rgba(240, 240, 240, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px;
      margin: 2px;
      top: 2px;
      right: 2px;
      transition: all 0.1s cubic-bezier(0.4, 0, 1, 1);

      &:hover,
      &:focus-visible {
        transition: all 0.1s cubic-bezier(0.4, 0, 1, 1);
        outline: transparent;
        border: 1px solid;
      }
    }
  }
}
