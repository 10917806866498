@import "src/styles/variables.scss";

header {
  // min-width: $sidebar-width;
  // max-width: $sidebar-width;
  // height: 100%;

  .navbar {
    // position: fixed;
    display: flex;
    flex-direction: row;
    // align-items: flex-start;
    // width: $sidebar-width;
    // height: 100%;
    width: 100%;
    // padding-top: 2rem;

    .navbar-nav {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      padding: 0 2rem;
    }

    .nav-item {
      // width: 100%;
      padding: 0;
      margin: 0.3rem 0;
    }

    .nav-link {
      border-radius: $border-radius;
      font-size: 1.1rem;
      padding: 0.5rem 1rem;

      &.active {
        background-color: $white;
        color: $dark;
        outline: 1px solid $dark;
      }
    }
  }
}
