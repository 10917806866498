@import "~bootstrap/scss/bootstrap";

.labeled-data-container {
  display: grid;
  grid-template-columns: 120px fit-content(100%);
  grid-gap: 0.7em 1.6em;
  // align-items: stretch;
  margin-bottom: 1.6em;
  max-width: 550px;

  .data-label,
  .data-value {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // min-height: var(--bs-input-height);
  }

  .section-label {
    min-height: 2rem;
    white-space: nowrap;
    margin: 0;
    grid-column: span 2;
  }

  .data-label {
    justify-self: end;
    // margin-top: 0.4rem;
    // margin-bottom: 0;

    label {
      text-align: end;
      min-height: var(--bs-input-height);
      padding: 0.375rem 0.75rem;
    }
  }

  .data-value {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    // > :not(label):not(.custom-select) {
    // }
    // justify-content: center;

    min-height: var(--bs-input-height);

    .currency {
      margin-left: auto;
    }
  }
}

.custom-select {
  --custom-select-border-color: var(--bs-border-color);
  --custom-select-border-color-focus: var(--bs-input-focus-border-color);
  --custom-select-focus-ring-shadow: 0 0 0 var(--bs-focus-ring-width)
    var(--bs-focus-ring-color);
  width: 100%;

  &.error {
    --custom-select-border-color: var(--bs-form-invalid-border-color);
    --custom-select-border-color-focus: var(--bs-form-invalid-border-color);
    --custom-select-focus-ring-shadow: 0 0 0 var(--bs-focus-ring-width)
      rgba(var(--bs-danger-rgb), 0.25);
    color: initial;
  }
}

.modal-body {
  .labeled-data-container {
    margin-left: 1em;
    margin-right: 1em;
  }
}
