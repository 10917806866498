@use "sass:color";
@import "src/styles/variables";

.applied-filters {
  display: flex;
  gap: 0.5rem;

  .applied-filter-chips {
    &__chip {
      &--field,
      &--field.disabled {
        font-weight: bold;
      }
      &--field,
      &--field.disabled,
      &--operator,
      &--operator.disabled {
        opacity: 1;

        @include button-variant(
          $color: shade-color(#0aa2c0, 30%),
          $disabled-color: shade-color(#0aa2c0, 30%),
          $background: tint-color(color.adjust(#0aa2c0, $saturation: -10%), 90%),
          $border: tint-color(#0aa2c0, 60%)
        );
      }

      &--condition {
        @include button-variant(
          // TODO: debug why cant I use variables (css or scss) of any kind or scope here? Get sass error "is not a color"
          $color: shade-color(#0aa2c0, 45%),
          $disabled-color: shade-color(#0aa2c0, 45%),
          $active-color: shade-color(#0aa2c0, 50%),
          $hover-color: shade-color(#0aa2c0, 50%),
          $background: tint-color(color.adjust(#0aa2c0, $saturation: -10%), 80%),
          $border: tint-color(#0aa2c0, 60%)
        );
        .condition-value {
          color: shade-color(#0aa2c0, 30%);
          font-weight: bold;
        }
      }
    }
  }
}
