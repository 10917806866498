@import "bootstrap";

.close-nav,
.back-nav {
  & > button {
    display: flex;
    align-items: center;
    padding: 0;
  }
}

.remove-assignment-button {
  &__errors {
    display: inline-flex;
    flex-direction: column;

    span {
      @extend .text-muted;
    }
  }
}
