section.agreements {
  height: 100%;
  width: 100%;
  position: relative;
  min-height: 400px;
}

.create-agreements-button {
  z-index: 50;
  top: 0;
  right: 0;
}

.assignment-tab-container {
  .create-agreements-button {
    top: -45px;
  }
}
