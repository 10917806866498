@import "src/styles/variables";

.creator-visible-indicator {
  position: relative;

  &__icon {
    color: $blue-400;

    &--absolute {
      left: -1.4rem;
      position: absolute;
    }

    &--static {
      margin-right: 0.5rem;
    }
  }

  &__label {
    text-decoration: $blue-400 underline dotted 0.14em;
    text-underline-position: under;
  }
}
